
//  embed youtube video
$('#hw-video-embed').prettyEmbed({
    videoID: "FUPIIOPdntc",
    previewSize: "hd",
    customPreviewImage: "",
    showInfo: false,
    showControls: true,
    loop: false,
    colorScheme: "dark",
    showRelated: false,
    useFitVids: true
});

//  init audio player
var audio;
var playlist;
var tracks;
var current;

init();
function init(){
    current = 0;
    audio = $( "audio" );
    playlist = $( "#playlist" );
    tracks = playlist.find( "li a" );
    len = tracks.length - 1;
    audio[0].volume = .50;
    playlist.find( "a" ).click( function( e ){
        e.preventDefault();
        link = $( this );
        current = link.parent().index();
        run( link, audio[0] );
    });
    audio[0].addEventListener( "ended", function( e ){
        current++;
        if ( current == len ){
            current = 0;
            link = playlist.find( "a" )[0];
        } else {
            link = playlist.find( "a" )[current];    
        }
        run( $( link ),audio[0] );
    });
}
function run( link, player ){
    player.src = link.attr( "href" );
    par = link.parent();
    par.addClass( "active" ).siblings().removeClass( "active" );
    audio[0].load();
    audio[0].play();
}

//  header onclick
$('header[class="banner"]').on('click', function(){
    var target = $('#content');
    $('html, body').stop().animate({
        'scrollTop': target.offset().top
    }, 600, 'swing', function () {
        window.location.hash = target.selector;
    });
})

//  form handling
$( "#contactForm" ).submit( function( event ) {

    // Stop form from submitting normally
    event.preventDefault();

    // Send the data using post
    var posting = $.post( "form.php", $( this ).serialize() );

    // Put the results in a div
    posting.done( function( data ) {
        $( "#form-feedback" ).html( data );
        $( "#contactForm" )[0].reset();
    } );

});

//  smooth scrolling
$('a[href^="#"]').on('click',function (e) {
    e.preventDefault();

    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 600, 'swing', function () {
        window.location.hash = target;
    });
});